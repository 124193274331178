import {
  FIND_CLOCK_START,
  FIND_CLOCK_SUCCESS,
  FIND_CLOCK_FAIL,
  FIND_TIMECARD_START,
  FIND_TIMECARD_SUCCESS,
  FIND_TIMECARD_FAIL,
  CREATE_CLOCK_START,
  CREATE_CLOCK_SUCCESS,
  CREATE_CLOCK_FAIL,
  UPDATE_CLOCK_SUCCESS,
  UPDATE_CLOCK_START,
  UPDATE_CLOCK_FAIL,
} from '../constants/index'

const initialState = {
  clockInfo: {
    isLoading: false,
    msg: '',
    data: null,
    errors: [],
  },
  timecardInfo: {
    isLoading: false,
    msg: '',
    data: null,
    errors: [],
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // FIND CLOCK
    case FIND_CLOCK_START:
      return {
        ...state,
        clockInfo: {
          ...state.clockInfo,
          isLoading: true,
        },
      }
    case FIND_CLOCK_SUCCESS:
      return {
        ...state,
        clockInfo: {
          success: true,
          isLoading: false,
          data: action.payload,
          errors: [],
        },
      }
    case FIND_CLOCK_FAIL:
      return {
        ...state,
        clockInfo: {
          ...state.clockInfo,
          isLoading: false,
          errors: action.payload,
        },
      }
    // FIND CLOCK
    case CREATE_CLOCK_START:
      return {
        ...state,
        clockInfo: {
          ...state.clockInfo,
          isLoading: true,
        },
      }
    case CREATE_CLOCK_SUCCESS:
      return {
        ...state,
        clockInfo: {
          success: true,
          isLoading: false,
          data: action.payload,
          errors: [],
        },
      }
    case CREATE_CLOCK_FAIL:
      return {
        ...state,
        clockInfo: {
          ...state.clockInfo,
          isLoading: false,
          errors: action.payload,
        },
      }

    // REPORTS CLOCK
    case FIND_TIMECARD_START:
      return {
        ...state,
        timecardInfo: {
          ...state.timecardInfo,
          isLoading: true,
        },
      }
    case FIND_TIMECARD_SUCCESS:
      return {
        ...state,
        timecardInfo: {
          success: true,
          isLoading: false,
          data: action.payload,
          errors: [],
        },
      }
    case FIND_TIMECARD_FAIL:
      return {
        ...state,
        timecardInfo: {
          ...state.timecardInfo,
          isLoading: false,
          errors: action.payload,
        },
      }

    // REPORTS CLOCK
    case UPDATE_CLOCK_START:
      return {
        ...state,
        timecardInfo: {
          ...state.timecardInfo,
          isLoading: true,
        },
      }
    case UPDATE_CLOCK_SUCCESS:
      return {
        ...state,
        timecardInfo: {
          ...state.timecardInfo,
          data: state.timecardInfo?.data?.map(
            (clock) =>
              clock.clockId === action.payload.clock._id
                ? { ...clock, ...action.payload.clock } // Update the matching clock with new data
                : clock, // Return the original clock if it doesn't match
          ),
        },
      }
    case UPDATE_CLOCK_FAIL:
      return {
        ...state,
        timecardInfo: {
          ...state.timecardInfo,
          isLoading: false,
          errors: action.payload,
        },
      }

    default:
      return state
  }
}

export default reducer

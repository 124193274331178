import React from 'react'
import PropTypes from 'prop-types'
import notiFxMp3 from '../assets/sounds/notification.mp3'
import notiFxOgg from '../assets/sounds/notification.ogg'
import notiBellMp3 from '../assets/sounds/bell-notification.mp3'
import notiBellOgg from '../assets/sounds/bell-notification.ogg'
import notiFailMp3 from '../assets/sounds/fail.mp3'
import notiFailOgg from '../assets/sounds/fail.ogg'

const PlaySound = ({ ...props }) => (
  <audio
    id="playSound"
    controls={props.controls || false}
    autoPlay={props.autoPlay || true}
    // src={props.src || notificationFx}
  >
    <source
      src={props.src || (props.fail ? notiFailMp3 : props.bell ? notiBellMp3 : notiFxMp3)}
      type="audio/mpeg"
    />
    <source
      src={props.src || (props.fail ? notiFailOgg : props.bell ? notiBellOgg : notiFxOgg)}
      type="audio/ogg"
    />
  </audio>
  // <iframe src={notificationFx} allow="autoplay" id="audio" title="sound"></iframe>
)

PlaySound.propTypes = {
  controls: PropTypes.any,
  autoPlay: PropTypes.any,
  src: PropTypes.string,
  fail: PropTypes.any,
  bell: PropTypes.any,
}

export default React.memo(PlaySound)

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import notify from 'src/utils/notify'
import { toast } from 'react-toastify'

export const CheckoutForm = ({ price, clientSecret, closeModal, setBookingCreated }) => {
  //   const [clientSecret, setClientSecret] = useState("");
  const stripe = useStripe()
  const elements = useElements()

  const handleChange = async (e) => {
    console.log(e)
    toast.dismiss()
  }

  // STEP 2: make the payment after filling the form properly
  const makePayment = async (e) => {
    e?.preventDefault && e.preventDefault()
    toast.dismiss()
    setTimeout(() => toast.loading(`Please Wait...`, { toastId: 'confirmCardSetup' }), 1)
    // const payload = await stripe.confirmCardSetup(clientSecret, {
    //   payment_method: {
    //     card: elements.getElement(CardElement),
    //   },
    // })
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    })
    setTimeout(() => toast.dismiss('confirmCardSetup'), 2)
    console.log(payload)
    if (payload?.paymentIntent?.status === 'succeeded') {
      closeModal()
      setBookingCreated(true)
      return notify({
        title: 'Success',
        msg: 'Booking Created Successfully',
        type: 'success',
      })
    }
    if (payload?.error?.message || !payload?.complete) {
      return notify({
        title: 'Error',
        msg: payload?.error?.message || 'Please Complete All fields',
        type: 'error',
      })
    }
    // if (payload?.complete) {
    //   return notify({ title: 'Error', msg: 'Success', type: 'success' })
    // }
  }

  return (
    <form id="payment-form" className="w-100" onSubmit={(e) => e.preventDefault()}>
      {/* <CardElement id="card-element" /> */}
      <CardElement id="card-element" onChange={handleChange} />
      <hr />
      <button id="submit-stripe" className="jelly-btn grad-success mt-4" onClick={makePayment}>
        <strong>${price}</strong> Book Trip
      </button>
    </form>
  )
}

CheckoutForm.propTypes = {
  price: PropTypes.any,
  clientSecret: PropTypes.any,
  closeModal: PropTypes.any,
  setBookingCreated: PropTypes.any,
}

export default CheckoutForm

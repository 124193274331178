import { toast } from 'react-toastify'
import { tripInfo } from './get-trip-info'
import { linearDistance, validCoords } from './helpers'
import isEmpty from './is-empty'
import notify from './notify'
import { isDriver } from './check-user-level'
import handleErr from './handle-error'

const generateHours = () => {
  const generatedHours = []
  for (let i = 0; i <= 12; i++) {
    generatedHours.push(i.toString().padStart(2, '0'))
  }
  return generatedHours
}

const generateMinutes = () => {
  const generatedMinutes = []
  for (let i = 0; i <= 59; i++) {
    generatedMinutes.push(i.toString().padStart(2, '0'))
  }
  return generatedMinutes
}

const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const weekDaysLong = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
const monthsName = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
const monthDays = () => {
  const days = []
  for (let i = 1; i <= 31; i++) {
    days.push(`${i}`)
  }
  return days
}

const getMonthDaysAmount = ({ year, monthNumb }) => {
  const getYearNumb = year || new Date().getFullYear()
  return new Date(getYearNumb, monthNumb, 0).getDate()
}

const formatTo12Hour = (timeString) => {
  try {
    if (timeString) {
      const [hours, minutes] = timeString.split(':').map(Number)
      const period = hours >= 12 ? 'PM' : 'AM'
      const formattedHours = hours % 12 || 12 // Convert 0 to 12 for 12 AM
      const formattedMinutes = minutes.toString().padStart(2, '0')

      return `${formattedHours}:${formattedMinutes} ${period}`
    } else {
      return ''
    }
  } catch (err) {
    handleErr({ err })
  }
}

const formatAMPM = (date) => {
  if (!date) return {}
  const time = {}
  time.hours = date.getHours()
  time.minutes = date.getMinutes()
  time.ampm = time.hours >= 12 ? 'pm' : 'am'
  time.hours = time.hours % 12
  time.hours = time.hours ? time.hours : 12 // the hour "0" should be "12"
  time.minutes = time.minutes < 10 ? '0' + time.minutes : time.minutes
  const strTime = time.hours + ':' + time.minutes + ' ' + time.ampm
  return { strTime, time, hours: time.hours, ampm: time.ampm }
}

const formatDate = ({
  dateData = new Date(),
  mmddyy,
  fullDate,
  noTime,
  timeOnly,
  forceShowDate,
  tomorrow,
  yesterday,
  nextMonth,
  lastMonth,
  nextYear,
  lastYear,
}) => {
  const dData = new Date(formatToHTMLinput({ dateData }))
  const nowDate = new Date()
  const dateShifter = tomorrow ? 1 : yesterday ? -1 : 0
  const monthShifter = nextMonth ? 1 : lastMonth ? -1 : 0
  const yearShifter = nextYear ? 1 : lastYear ? -1 : 0

  const day = weekDays[dData.getDay() + dateShifter]
  const dd = String(dData.getDate() + dateShifter).padStart(2, '0')
  const showDay = forceShowDate
    ? `${day} ${dd}`
    : nowDate.getDate() === dData.getDate() + dateShifter &&
      nowDate.getMonth() === dData.getMonth() + monthShifter &&
      nowDate.getFullYear() === dData.getFullYear() + yearShifter
    ? ''
    : `${day} ${dd}`
  const mm = String(dData.getMonth() + monthShifter).padStart(1, '0') //January is 0!
  const showMonth = nowDate.getMonth() !== dData.getMonth() + monthShifter ? monthsName[mm] : ''
  const yyyy = String(dData.getFullYear() + yearShifter).padStart(2, '0')
  const showYear = nowDate.getFullYear() !== dData.getFullYear() + yearShifter ? yyyy : ''
  const h = dData.getHours()
  const m = dData.getMinutes()
  const dShifted = new Date(yyyy, mm, dd, h, m)

  return mmddyy
    ? (dData.getMonth() > 8 ? dData.getMonth() + 1 : '0' + (dData.getMonth() + 1)) +
        '/' +
        (dData.getDate() > 9 ? dData.getDate() : '0' + dData.getDate()) +
        '/' +
        String(dData.getFullYear()).slice(2)
    : timeOnly
    ? formatAMPM(dShifted).strTime
    : fullDate &&
      `${formatAMPM(dShifted).strTime} - ${day} ${dd} - ${monthsName[mm]} - ${yyyy}`.indexOf(
        'NaN',
      ) === -1
    ? `${formatAMPM(dShifted).strTime} - ${day} ${dd} - ${monthsName[mm]} - ${yyyy}`
    : `${day} ${dd} - ${monthsName[mm]} - ${yyyy}` &&
      `${day} ${dd} - ${monthsName[mm]} - ${yyyy}`.indexOf('NaN') === -1
    ? `${noTime ? '' : formatAMPM(dShifted).strTime} ${
        showDay ? ` ${showDay}` : ''
      } ${showMonth} ${showYear}`
    : ''
}

// const shiftDat2 = ({
//   dateData = new Date(),
//   tomorrow,
//   yesterday,
//   nextMonth,
//   lastMonth,
//   nextYear,
//   lastYear,
//   hours,
//   minutes,
// }) => {
//   const dateShifter = tomorrow ? 1 : yesterday ? -1 : 0
//   const monthShifter = nextMonth ? 1 : lastMonth ? -1 : 0
//   const yearShifter = nextYear ? 1 : lastYear ? -1 : 0

//   const dd = String(dateData.getDate() + dateShifter).padStart(2, '0')
//   const mm = String(dateData.getMonth() + monthShifter).padStart(2, '0') //January is 0!
//   const yyyy = String(dateData.getFullYear() + yearShifter).padStart(2, '0')
//   const h = hours || dateData.getHours()
//   const m = minutes || dateData.getMinutes()

//   return new Date(yyyy, mm, dd, h, m)
// }

const shiftDate = ({
  dateData = new Date(),
  tomorrow,
  yesterday,
  nextMonth,
  lastMonth,
  nextYear,
  lastYear,
  hours,
  minutes,
}) => {
  const dateShifter = tomorrow ? 1 : yesterday ? -1 : 0
  const monthShifter = nextMonth ? 1 : lastMonth ? -1 : 0
  const yearShifter = nextYear ? 1 : lastYear ? -1 : 0

  const dd = String(dateData.getDate() + dateShifter).padStart(2, '0')
  const mm = String(dateData.getMonth() + monthShifter).padStart(2, '0') //January is 0!
  const yyyy = String(dateData.getFullYear() + yearShifter).padStart(2, '0')
  const h = String(hours || dateData.getHours()).padStart(2, '0')
  const m = String(minutes || dateData.getMinutes()).padStart(2, '0')

  return new Date(
    yyyy,
    mm,
    dd,
    h,
    m,
    // String(Number(m) - dateData.getTimezoneOffset()).padStart(2, '0'),
  )
}

const isValidDate = (d) => d instanceof Date && !isNaN(d)

const timeDifference = ({ startTime, stopTime }) => {
  const strtT = isValidDate(startTime) ? startTime : new Date()
  const stpT = isValidDate(stopTime) ? stopTime : new Date()
  const diffMs = Math.abs(stpT - strtT)
  const days = Math.floor(diffMs / 86400000) // days
  const hours = Math.floor((diffMs % 86400000) / 3600000) // hours
  const minutes = Math.round(((diffMs % 86400000) % 3600000) / 60000) // minutes
  const seconds = Math.round(diffMs / 1000) // seconds
  const formatted = `${days && days > 0 ? days + 'd' : ''}${
    hours && hours > 0 ? ` ${hours}h` : ''
  }${minutes && minutes > 0 ? ` ${minutes}min` : ''}`

  return {
    diffMs,
    days,
    hours,
    minutes,
    seconds,
    formatted,
  }
}

const formatToHTMLinput = ({
  dateData = new Date(),
  month,
  hours,
  minutes,
  noTime,
  roundTo5Min,
}) => {
  try {
    const formatTime = {}
    formatTime.local = dateData
    if (hours) formatTime.local.setHours(Number(hours))
    if (minutes) {
      formatTime.local.setMinutes(+(roundTo5Min ? Math.ceil(+minutes / 5) * 5 : minutes))
    }
    // if (minutes) formatTime.local.setMinutes(Number(minutes))
    // formatTime.local.setMinutes(
    //   (minutes || formatTime.local.getMinutes()) - formatTime.local.getTimezoneOffset(),
    // )
    const year = formatTime.local.getFullYear()
    const mth = month
      ? String(month).padStart(2, '0')
      : String(formatTime.local.getMonth() + 1).padStart(2, '0')
    const dd = String(formatTime.local.getDate()).padStart(2, '0')
    const hh = hours
      ? String(hours).padStart(2, '0')
      : String(formatTime.local.getHours()).padStart(2, '0')
    const mm = minutes
      ? String(roundTo5Min ? Math.ceil(+minutes / 5) * 5 : minutes).padStart(2, '0')
      : // ? String(minutes).padStart(2, '0')
        String(
          roundTo5Min
            ? Math.ceil(+formatTime.local.getMinutes() / 5) * 5
            : formatTime.local.getMinutes(),
        ).padStart(2, '0')
    // : String(formatTime.local.getMinutes()).padStart(2, '0')
    return noTime ? `${year}-${mth}-${dd}` : `${year}-${mth}-${dd}T${hh}:${mm}`
  } catch (err) {
    console.error(err)
    return ''
  }
}

const roundTimeToNearest5Minutes = (dateString) => {
  try {
    const dateData = new Date(dateString)
    const minutes = dateData?.getMinutes()
    const roundedMinutes = Math.ceil(minutes / 5) * 5
    dateData?.setMinutes(roundedMinutes)
    dateData?.setSeconds(0)
    dateData?.setMilliseconds(0)
    // const isoString = date.toISOString();
    // return isoString;
    return formatToHTMLinput({ dateData })
  } catch (err) {
    handleErr({ err })
    return formatToHTMLinput({ dateData: new Date(dateString) })
  }
}

const todayRange = () =>
  `${formatToHTMLinput({
    hours: '00',
    minutes: '00',
  })}_${formatToHTMLinput({
    hours: '23',
    minutes: '59',
  })}`

const tomorrowRange = () =>
  `${formatToHTMLinput({
    dateData: shiftDate({
      tomorrow: true,
      hours: '00',
      minutes: '00',
    }),
  })}_${formatToHTMLinput({
    dateData: shiftDate({
      tomorrow: true,
      hours: '23',
      minutes: '59',
    }),
  })}`

const yesterdayRange = () =>
  `${formatToHTMLinput({
    dateData: shiftDate({
      yesterday: true,
      hours: '00',
      minutes: '00',
    }),
  })}_${formatToHTMLinput({
    dateData: shiftDate({
      yesterday: true,
      hours: '23',
      minutes: '59',
    }),
  })}`

const nextDayRange = ({ constraint }) =>
  `${formatToHTMLinput({
    dateData: new Date(
      shiftDate({
        dateData: new Date(constraint),
        hours: '0',
        minutes: '0',
        tomorrow: true,
      }),
    ),
  })}_${formatToHTMLinput({
    dateData: new Date(
      shiftDate({
        dateData: new Date(constraint),
        hours: '23',
        minutes: '59',
        tomorrow: true,
      }),
    ),
  })}`

// const calcExpectedTime = ({ refTime, duration, increaseMins = 0, calcBackwards }) => {
//   if (calcBackwards && duration.value >= 0) {
//     //duration.value in seconds
//     const refYear = new Date(refTime).getFullYear()
//     const refMonth = (new Date(refTime).getMonth() + 1).toString().padStart(2, '0')
//     const refDate = new Date(refTime).getDate().toString().padStart(2, '0')
//     const refHour = new Date(refTime).getHours()
//     const refMins = new Date(refTime).getMinutes()
//     // const durationHours = Math.round((duration.value * 1000) / 86400000)
//     const durationMins = Math.round((duration.value * 1000) / 60000) + increaseMins
//     const durationHours = durationMins / 60
//     const substractMins = refMins - durationMins
//     const threshold = substractMins < 0 ? 1 : 0
//     const substractHours = refHour - durationHours - threshold
//     const hours =
//       substractHours === 0
//         ? refHour
//         : substractHours > 0
//         ? substractHours
//         : Math.abs(24 + substractHours)
//     const calcMins = substractMins >= 0 ? substractMins : Math.abs(60 + substractMins)
//     const mins = Math.abs(+calcMins) === 60 ? 59 : Math.abs(+calcMins)
//     console.log(duration, refMins, durationMins, substractMins, calcMins, threshold, substractHours)
//     // const mins =
//     //   Math.abs(Math.ceil(+calcMins / 5) * 5) === 60 ? 59 : Math.abs(Math.ceil(+calcMins / 5) * 5)
//     const time = `${refYear}-${refMonth}-${refDate}T${hours?.toString().padStart(2, '0')}:${mins
//       ?.toString()
//       .padStart(2, '0')}`
//     return time
//   } else {
//     return ''
//   }
// }

const stopWatch = ({ start }) => {
  let [milliseconds, seconds, minutes, hours] = [0, 0, 0, 0]
  // let timerRef = document.querySelector('.timerDisplay')
  let int = null
  clearInterval(int)

  // document.getElementById('startTimer').addEventListener('click', () => {
  //   if (int !== null) {
  //     clearInterval(int)
  //   }
  //   int = setInterval(displayTimer, 10)
  // })

  // document.getElementById('pauseTimer').addEventListener('click', () => {
  //   clearInterval(int)
  // })

  // document.getElementById('resetTimer').addEventListener('click', () => {
  //   clearInterval(int)
  //   ;[milliseconds, seconds, minutes, hours] = [0, 0, 0, 0]
  //   timerRef.innerHTML = '00 : 00 : 00 : 000 '
  // })

  const displayTimer = () => {
    milliseconds += 10
    if (+milliseconds === 1000) {
      milliseconds = 0
      seconds++
      if (+seconds === 60) {
        seconds = 0
        minutes++
        if (+minutes === 60) {
          minutes = 0
          hours++
        }
      }
    }

    let h = hours < 10 ? '0' + hours : hours
    let m = minutes < 10 ? '0' + minutes : minutes
    let s = seconds < 10 ? '0' + seconds : seconds
    // let ms =
    //   milliseconds < 10
    //     ? '00' + milliseconds
    //     : milliseconds < 100
    //     ? '0' + milliseconds
    //     : milliseconds

    // timerRef.innerHTML = ` ${h || ''} : ${m} : ${s} : ${ms}`
    return ` ${h || ''} : ${m} : ${s}`
  }
  if (start) int = setInterval(displayTimer, 10)
  return displayTimer()
}

const secondsToTime = (seconds) => {
  // const h = new Date(seconds * 1000).toISOString().substring(11, 16)
  // const m = new Date(seconds * 1000).toISOString().substring(14, 19)
  const h = Math.floor(Number(seconds) / 3600)
  const m = Math.floor((Number(seconds) - h * 3600) / 60)
  // const m = Math.floor((Number(seconds) % 3600) / 60)
  // const m = Math.floor(Number(seconds) / 60)
  // let seconds = totalSeconds % 60;

  // console.log('hours: ' + hours)
  // console.log('minutes: ' + minutes)
  // console.log("seconds: " + seconds);

  // If you want strings with leading zeroes:
  // hours = String(h).padStart(2, '0')
  // minutes = String(m).padStart(2, '0')
  // seconds = String(seconds).padStart(2, "0");
  // console.log(hours + ":" + minutes + ":" + seconds);

  return Number(h) > 0 ? `${String(h).padStart(1, '0')}:${String(m).padStart(1, '0')}` : Number(m)
}

const isInTimeRange = ({ startTime = Date.now(), constraint }) =>
  (constraint === 'yesterday' &&
    startTime >= Date.parse(yesterdayRange().split('_')[0]) &&
    startTime <= Date.parse(yesterdayRange().split('_')[1])) ||
  (constraint === 'today' &&
    startTime >= Date.parse(todayRange().split('_')[0]) &&
    startTime <= Date.parse(todayRange().split('_')[1])) ||
  (constraint === 'tomorrow' &&
    startTime >= Date.parse(tomorrowRange().split('_')[0]) &&
    startTime <= Date.parse(tomorrowRange().split('_')[1])) ||
  (constraint?.split('_').length >= 2 &&
    startTime >= Date.parse(constraint.split('_')[0]) &&
    startTime <= Date.parse(constraint.split('_')[1]))
    ? true
    : false

const isAbsense = ({ showAllDay, user, checkdate, absenseData }) =>
  absenseData?.find((x) => {
    const setFrom = showAllDay && x.from ? x.from.substring(0, 10) : x.from
    const setTo = showAllDay && x.to ? x.to.substring(0, 10) : x.to
    return (
      x.user._id === (user?._id || user) && // Check if absense is for the current driver
      x.status === 'Approved' && // Check if absense status is Approved
      checkdate >= setFrom && // Check if checkdate is after or on the absense start date
      checkdate <= setTo // Check if checkdate is before or on the absense end date
    )
  })

const isInHolidaysTimeFrame = ({ startTime, uData, holidaysData }) =>
  (uData?.onBreak?.holidaysDates &&
    isInTimeRange({ startTime, constraint: uData?.onBreak?.holidaysDates })) ||
  holidaysData?.find((x) => startTime?.substring(0, 10) === x.date.substring(0, 10))

const isInBreakTimeFrame = ({ startTime, uData }) =>
  uData?.onBreak?.startBreakTime &&
  isInTimeRange({
    startTime,
    constraint: `${formatToHTMLinput({
      dateData: new Date(new Date(uData?.onBreak?.startBreakTime)),
    })}_${formatToHTMLinput({
      dateData: new Date(
        new Date(uData?.onBreak?.startBreakTime).getTime() +
          (uData?.onBreak?.breakTime || 0) * 60000,
      ),
    })}`,
  })

const isLocTimeOff = (
  timeDiff = {
    days: 0,
    hours: 0,
    minutes: 0,
  },
) => timeDiff.days > 0 || timeDiff.hours > 0 || timeDiff.minutes >= 1

const isDelay = ({
  limit,
  lastUpdate,
  timeDiff = lastUpdate
    ? timeDifference({
        startTime: new Date(),
        stopTime: lastUpdate,
      })
    : {},
}) =>
  // timeDiff?.days === 0 &&
  // timeDiff?.hours === 0 &&
  // timeDiff?.hours === 0 &&
  // timeDiff?.minutes === 0 &&
  timeDiff?.seconds >= limit

const isToday = (constraint) => constraint === todayRange()

const isWithInToday = (constraint = formatToHTMLinput({})) => {
  try {
    if (constraint === todayRange()) return true
    const dParts = constraint?.split('_')
    const dRange1 = Date.parse(dParts[0])
    const dRange2 = Date.parse(dParts[1] || dParts[0])
    const tRange1 = Date.parse(todayRange()?.split('_')[0])
    const tRange2 = Date.parse(todayRange()?.split('_')[1])
    return dRange1 >= tRange1 && dRange2 <= tRange2
  } catch (err) {
    handleErr({ err })
  }
}

const isSameWeekDay = (constraint) => {
  const parts = constraint?.split('_') || []
  const startDate = parts[0]
  const endDate = parts[1]
  return (
    startDate &&
    endDate &&
    new Date(startDate).getDate() === new Date(endDate).getDate() &&
    new Date(startDate).getMonth() === new Date(endDate).getMonth()
  )
}

const isLate = (time1, time2 = new Date()) =>
  time1?.indexOf('NaN') === -1 && Date.parse(time2) > Date.parse(time1)

const isPast = ({ constraint1, monthOnly }) =>
  !monthOnly && Date.parse(constraint1) < Date.parse(shiftDate({ yesterday: true }))
    ? true
    : new Date(constraint1).getMonth() === new Date().getMonth() &&
      Date.parse(constraint1) < Date.parse(shiftDate({ yesterday: true }))

const isFuture = ({ constraint1 }) =>
  Date.parse(constraint1) >= Date.parse(shiftDate({ tomorrow: true }))

const isOnTimeToPickup = ({ trip, time1, constraint = todayRange() }) => {
  try {
    const { times, destIdxToShow, timeIdx = 1 } = tripInfo({ trip })
    // const timeIdx = destIdxToShow ? destIdxToShow + 1 : 1
    const tripTime = formatToHTMLinput({
      dateData: new Date(
        times[`pickupTime${timeIdx}`] ||
          times[`arriveTime${timeIdx}`] ||
          times[`startTime${timeIdx}`] ||
          times[`expectedStartTime${timeIdx}`] ||
          times[`expectedPickupTime${timeIdx}`] ||
          Date.now(),
      ),
    })

    const pkdpTime = times && tripTime ? tripTime : new Date()

    const timeDiff = timeDifference({
      startTime: time1
        ? new Date(time1)
        : isWithInToday(constraint)
        ? // : isToday(constraint)
          new Date()
        : isSameWeekDay(constraint)
        ? new Date(tripTime)
        : new Date().setHours(0),
      stopTime: new Date(pkdpTime),
    })
    return (
      (Math.abs(timeDiff?.days) === 0 &&
        Math.abs(timeDiff?.hours) === 0 &&
        timeDiff?.minutes <= 45) ||
      new Date(pkdpTime) <= new Date()
    )
  } catch (err) {
    handleErr({ err })
  }
}

const secondsToHms = (secs) => {
  const h = Math.floor(Number(secs) / 3600)
  const m = Math.floor((Number(secs) % 3600) / 60)
  const s = Math.floor((Number(secs) % 3600) % 60)
  return {
    h,
    m,
    hours: ('0' + h).slice(-2),
    minutes: ('0' + m).slice(-2),
    seconds: ('0' + s).slice(-2),
    full: ('0' + h).slice(-2) + ':' + ('0' + m).slice(-2) + ':' + ('0' + s).slice(-2),
  }
}

const increaseTime = ({ time, hours, minutes, roundTo5Min }) => {
  const timeNow = time ? new Date(time) : new Date()
  const nowH = timeNow.getHours()
  const nowM = timeNow.getMinutes()
  const sumMins = nowM + Number(minutes)
  const minsThreshold = sumMins >= 60 ? Math.floor(sumMins / 60) : 0
  const etaMin = roundTo5Min ? Math.ceil(sumMins / 5) * 5 : sumMins
  const etaM = etaMin >= 60 ? etaMin - 60 : etaMin
  const sumHours = nowH + Number(hours) + minsThreshold
  const H = sumHours === 0 ? 12 : sumHours > 24 ? sumHours - 24 : sumHours
  const etaH = H > 12 ? H - 12 : H
  const ampm = sumHours >= 12 && sumHours <= 23 ? ' pm' : ' am'
  const etaDate = timeNow
  etaDate.setHours(sumHours)
  etaDate.setMinutes(etaM)
  etaDate.setSeconds(0)
  etaDate.setMilliseconds(0)
  // etaDate.setMinutes(etaM)
  return {
    eta: etaH + ':' + ('0' + String(etaM)).slice(-2) + ampm,
    etaH,
    etaM,
    hours24: H,
    sumMins,
    timeToMin: +etaH * 60 + +etaM,
    timeToSecs: +etaH * 60 * 60 + +etaM * 60,
    etaDate: formatToHTMLinput({ dateData: new Date(etaDate) }),
  }
}

const getETA = ({ onTrip, time, loc1, loc2, increase = 0, meters }) => {
  try {
    //loc1 is driver lastLocation
    if (meters || (validCoords({ coords: loc1 }) && validCoords({ coords: loc2 }))) {
      const { lat: lat1, lng: lng1 } = loc1 || {}
      const { lat: lat2, lng: lng2 } = loc2 || {}
      const distance = meters || linearDistance({ lat1, lng1, lat2, lng2 })
      // console.log(loc1)
      const driverSpeed = onTrip && loc1?.speed && loc1?.speed > 8.9408 ? loc1?.speed : 8.9408 // 25 miles/h = 11.176 meter/sec
      // const driverSpeed = onTrip && loc1.speed && loc1.speed > 11.176 ? loc1.speed : 11.176 // 25 miles/h = 11.176 meter/sec
      // const driverSpeed = onTrip && loc1.speed ? loc1.speed : 15.6464 // 35 miles/h = 15.6464 meter/sec
      // const driverSpeed = onTrip && loc1.speed ? loc1.speed : 17.8816 // 40 miles/h = 17.8816 meter/sec
      // const driverSpeed = onTrip && loc1.speed ? loc1.speed : 20.1168 // 45 miles/h = 20.1168 meter/sec
      // const driverSpeed = onTrip && loc1.speed ? loc1.speed : 20.1168 // 55 miles/h = 24.5872 meter/sec
      // const driverSpeed = onTrip && loc1.speed ? loc1.speed : 20.1168 // 65 miles/h = 29.0576 meter/sec
      // const driverSpeed = onTrip && loc1.speed ? loc1.speed : 20.1168 // 70 miles/h = 31.2928 meter/sec
      const secs = (distance?.meter || meters) / driverSpeed || 0
      // console.log(secs, distance?.meter, driverSpeed)
      //const increase = 0 // 300 //secs = 5min
      // const increase = 600 //secs = 10min
      // const increase = 900 //secs = 15min
      const { h, m, seconds, full } = secondsToHms(secs + increase)
      const { eta, etaH, etaM, hours24, sumMins, etaDate, timeToMin, timeToSecs } = increaseTime({
        time,
        hours: h,
        minutes: Math.ceil(m / 5) * 5,
        roundTo5Min: true,
      })
      return eta?.indexOf('NaN') === -1 && h >= 0 && m >= 0 && etaH >= 0 && etaM >= 0
        ? {
            eta,
            hours: h,
            minutes: Math.ceil(m / 5) * 5,
            etaH,
            etaM,
            hours24,
            sumMins,
            etaDate,
            timeToMin,
            timeToSecs,
            distance,
          }
        : ''
    } else {
      return ''
    }
  } catch (err) {
    handleErr({ err })
  }
}

const timeValidations = ({ trip, idx: tIdx, timeField, timePickerDate }) => {
  try {
    toast.dismiss()
    const { timeIdx, destLen } = tripInfo({ trip })
    const idx = tIdx ? tIdx : timeIdx
    if (timePickerDate && Date.parse(timePickerDate) < Date.now()) {
      notify({
        toastId: 'timeerror',
        msg: `Error ! Time must be Later than Now`,
        type: `error`,
      })
      return false
    }
    const expectedStartTime = trip?.times ? trip.times[`expectedStartTime${idx}`] : ''
    const expectedPickupTime = trip?.times ? trip.times[`expectedPickupTime${idx}`] : ''
    const expectedDropoffTime = trip?.times ? trip.times[`expectedDropoffTime${idx}`] : ''
    if (timeField?.indexOf('expectedStartTime') >= 0) {
      if (
        (expectedPickupTime && Date.parse(timePickerDate) > Date.parse(expectedPickupTime)) ||
        (expectedDropoffTime && Date.parse(timePickerDate) > Date.parse(expectedDropoffTime))
      ) {
        notify({
          toastId: 'expectedStartTime',
          msg: `Error ! Start Time must be Earlier than Pick Up and Drop Off`,
          type: `error`,
        })
        return false
      }
    }
    if (timeField?.indexOf('expectedPickupTime') >= 0) {
      const msg =
        // expectedStartTime && Date.parse(timePickerDate) < Date.parse(expectedStartTime)
        //   ? `Error ! Pick Up must be Later than Start Time`
        //   :
        destLen > 1 &&
        expectedDropoffTime &&
        Date.parse(expectedDropoffTime) >= Date.now() &&
        Date.parse(timePickerDate) > Date.parse(expectedDropoffTime)
          ? `Error ! Pick Up must be Earlier than Drop Off`
          : ''
      if (msg) {
        notify({
          toastId: 'expectedPickupTime',
          msg,
          type: `error`,
        })
        return false
      }
    }
    if (timeField?.indexOf('expectedDropoffTime') >= 0) {
      if (
        (expectedStartTime && Date.parse(timePickerDate) < Date.parse(expectedStartTime)) ||
        (expectedDropoffTime && Date.parse(timePickerDate) < Date.parse(expectedPickupTime))
      ) {
        notify({
          toastId: 'expectedPickupTime',
          msg: `Error ! Drop Off be must Later than Start Time and Pick Up `,
          type: `error`,
        })
        return false
      }
    }
    return true
  } catch (error) {
    console.log(error)
    return true
  }
}

const sortNotesByTime = (a, b) => Date.parse(b?.date) - Date.parse(a?.date)

const constraintToArr = ({ constraint }) => {
  const daysArr = []
  if (constraint && !isEmpty(constraint)) {
    const arr = constraint.split('_')
    if (arr.length >= 2) {
      for (let d = new Date(arr[0]); d <= new Date(arr[1]); d.setDate(d.getDate() + 1)) {
        daysArr.push({
          wDay: Intl.DateTimeFormat(undefined, { weekday: 'long' })
            .format(new Date(d))
            .toLowerCase(),
          // wDay: new Date().toLocaleTimeString(undefined, { weekday: "long" }),
          mDay: `${new Date(d).getDate()}`,
          monthNumb: `${new Date(d).getMonth() + 1}`,
          fullYear: `${new Date(d).getFullYear()}`,
        })
      }
      // console.log(daysArr.reduce((acc, obj) => [...acc, obj.wDay, obj.mDay], []));
    }
    return daysArr
  }
}

const daysScheduled = ({ trip, constraint }) => {
  return constraintToArr({ constraint }).filter(
    (d) => trip.schedule && trip.schedule.some((s) => s === d.wDay || s === d.mDay),
  )
}

const convertTime = (time) => {
  try {
    const duration = time.toString().split('.')
    const durationHrs = duration[0]
    let mint
    let mintStr = '0.' + duration[1]
    mintStr = parseFloat(mintStr)
    mint = mintStr
    const duration2 = mint * 60
    const durationMin = duration2.toString().split('.')
    return `${durationHrs > 0 ? `${durationHrs}h` : ''} ${
      durationMin[0] > 0 ? `${durationMin[0]}m` : ''
    }`
  } catch (err) {
    handleErr({ err })
  }
}

export {
  isAbsense,
  daysScheduled,
  weekDays,
  weekDaysLong,
  monthDays,
  monthsName,
  getMonthDaysAmount,
  formatTo12Hour,
  formatAMPM,
  formatDate,
  shiftDate,
  timeDifference,
  formatToHTMLinput,
  roundTimeToNearest5Minutes,
  // calcExpectedTime,
  generateHours,
  generateMinutes,
  stopWatch,
  secondsToTime,
  isInTimeRange,
  isInHolidaysTimeFrame,
  isInBreakTimeFrame,
  isLocTimeOff,
  todayRange,
  tomorrowRange,
  yesterdayRange,
  nextDayRange,
  isToday,
  isWithInToday,
  isSameWeekDay,
  isOnTimeToPickup,
  increaseTime,
  getETA,
  isLate,
  isPast,
  isFuture,
  timeValidations,
  sortNotesByTime,
  constraintToArr,
  isDelay,
  convertTime,
}

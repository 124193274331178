import { toast } from 'react-toastify'
import handleErr from './handle-error'
import notify from './notify'
import { slowDown, validCoords } from './helpers'

const geoLocation = () =>
  new Promise(async (resolve) => {
    try {
      const success = (pos = {}) => {
        const { longitude: lng = 0, latitude: lat = 0, heading, speed } = pos.coords || {}
        if (!validCoords({ coords: { lng, lat } })) {
          toast?.dismiss()
          setTimeout(() => {
            toast.error(`Error getting your Location...`, { toastId: 'geolocation' })
          }, 7)
        }
        resolve({ lng: Number(lng), lat: Number(lat), heading, speed })
      }
      const options = {
        enableHighAccuracy: true,
        timeout: 7000,
        maximumAge: 0,
      }
      const error = async (err) => {
        toast?.dismiss()
        console.error(`ERROR(${err?.code || ''}): ${err?.message || ''}`)
        // await slowDown(707)
        // notify({
        //   toastId: 'geolocation',
        //   type: 'warn',
        //   msg: 'Weak Signal! GPS Location Error!!! ',
        //   confirm: () => {
        //     navigator.geolocation.getCurrentPosition(success, error, options)
        setTimeout(() => {
          toast.error(`Error getting your Location...`, { toastId: 'geolocation' })
        }, 7)
        //   },
        //   confirmOnly: true,
        //   confirmText: 'Retry',
        //   // confirm: () => {
        //   //   navigator.geolocation.getCurrentPosition(success, error, options)
        //   // },
        // })
        resolve({ lng: 0, lat: 0 })
      }
      if (navigator && navigator.geolocation) {
        navigator.permissions.query({ name: 'geolocation' }).then(async (result) => {
          const { state } = result
          if (state === 'granted' || state === 'prompt') {
            navigator.geolocation.getCurrentPosition(success, error, options)
          } else if (result.state === 'denied') {
            toast?.dismiss()
            // Don't do anything if the permission was denied.
            await slowDown(707)
            notify({
              toastId: 'geolocation',
              type: 'warn',
              msg: 'Location Services is Blocked!!! Please Allow Location Services on your device Settings!!!',
              // confirm: () => {
              //   navigator.geolocation.getCurrentPosition(success, error, options)
              // },
            })
            // clearWatch(navId)
            // console.log('Location denied')
            resolve({ lng: 0, lat: 0 })
          }
          result.addEventListener('change', () => {
            if (state === 'granted') {
              navigator.geolocation.getCurrentPosition(success, error, options)
            }
            if (result.state === 'denied') {
              navigator.geolocation.getCurrentPosition(success, error, options)
            }
          })
        })
      } else {
        error()
      }
    } catch (err) {
      handleErr({ err })
    }
  })

export default geoLocation

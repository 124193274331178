// import socket from 'src/services/socket'
import store from 'src/store'
import {
  COMPANY_LOGIN_FAIL,
  COMPANY_LOGIN_REQUEST,
  COMPANY_LOGIN_SUCCESS,
  COMPANY_LOGOUT_SUCCESS,
  // OTP_REQUEST,
  OTP_RESEND_FAIL,
  OTP_RESEND_REQUEST,
  OTP_RESEND_SUCCESS,
  OTP_SUCCESS,
  OTP_VERIFY_FAIL,
  OTP_VERIFY_REQUEST,
  SAVE_DEVICE_DETAILS,
  COMPANY_REGISTER_FAIL,
  COMPANY_REGISTER_REQUEST,
  COMPANY_REGISTER_SUCCESS,
  // GET_USER_FAIL,
  // GET_USER_REQUEST,
  // GET_USER_SUCCESS,
  // USER_AUTH_FAIL,
  // USER_AUTH_REQUEST,
  // USER_AUTH_SUCCESS,
  // USER_LOGOUT_FAIL,
  // USER_LOGOUT_SUCCESS,
} from '../constants'

// import axiosPrivate, { axiosPublic } from '../services/api'
import { axiosPublic } from '../services/api'
// import { WatchLocation } from 'src/utils/watchLocation'
import socket from 'src/services/socket'
import { userResetPwd } from './authActions'
import { toast } from 'react-toastify'
import handleErr from '../utils/handle-error'
import handleIDB from '../utils/handle-indexed-db'

//COMPANY REGISTER
export const companyRegisterAction = (obj, admin, cb) => async (dispatch) => {
  dispatch({
    type: COMPANY_REGISTER_REQUEST,
  })
  try {
    const { data } = await axiosPublic.post(`/companies/register`, obj)

    cb && cb()
    const { success, errors } = data
    if (success) {
      dispatch({
        type: COMPANY_REGISTER_SUCCESS,
        payload: data,
      })
    } else {
      handleErr({
        err: errors,
        dispatch,
        alert: { msg: `Error creating Company! Please try again` },
        reduxType: COMPANY_REGISTER_FAIL,
        payload: data,
      })
      // dispatch({
      //   type: COMPANY_REGISTER_FAIL,
      //   payload: data,
      // })
    }
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: { msg: `Error creating Company! Please try again` },
      reduxType: COMPANY_REGISTER_FAIL,
    })
    // dispatch({
    //   type: COMPANY_REGISTER_FAIL,
    //   payload: err?.response?.data?.errors || 'Unknown error',
    // })
    // else
    //   dispatch({
    //     type: COMPANY_REGISTER_FAIL,
    //     payload: err?.response?.data ? err.response.data.errors[0] : 'Unknown error',
    //   })
  }
}

//COMPANY LOGIN
export const companyLoginAction = (obj, loginSuccessHandler) => async (dispatch) => {
  dispatch({ type: COMPANY_LOGIN_REQUEST })

  try {
    const { data } = await axiosPublic.post(`/users/loginV2`, obj)
    const { success, user, token, emailVerification, errors } = data || {}
    // const { data } = await axiosPublic.post(`/users/login`, obj)

    // localStorage.setItem('companyData', JSON.stringify(data.user.company || data.user))
    // localStorage.setItem('userData', JSON.stringify(data.user))
    // localStorage.setItem('XPERDRIVER_TOKEN', JSON.stringify(data.token))

    if (success) {
      if (loginSuccessHandler) loginSuccessHandler()
      // const companyData = data.user.company || data.user
      // const userInfo = JSON.parse(JSON.stringify(data.user)) // deep clone
      // delete userInfo.company // remove image (token too big)
      // userInfo.company = { _id: companyData._id }
      // handleIDB.put({ model: 'Auth', data: companyData, _id: 'companyData' })
      // handleIDB.put({ model: 'Auth', data: userInfo, _id: 'userData' })
      // handleIDB.put({ model: 'Auth', data: data.token, _id: 'XPERDRIVER_TOKEN' })
      // dispatch({
      //   type: COMPANY_LOGIN_SUCCESS,
      //   payload: data,
      // })
      if (obj.requireOtp) {
        dispatch({
          type: OTP_SUCCESS,
          payload: data,
        })
      } else {
        const companyData = user.company || user
        const userInfo = JSON.parse(JSON.stringify(user)) // deep clone
        // delete userInfo.company // remove image (token too big)
        userInfo.company = { _id: companyData._id } // remove image (token too big)
        handleIDB.put({ model: 'Auth', data: companyData, _id: 'companyData' })
        handleIDB.put({ model: 'Auth', data: userInfo, _id: 'userData' })
        handleIDB.put({ model: 'Auth', data: token, _id: 'XPERDRIVER_TOKEN' })
        dispatch({
          type: COMPANY_LOGIN_SUCCESS,
          payload: data,
        })
        socket?.connect && socket.connect()
      }
    } else {
      if (obj.email && emailVerification) {
        return dispatch(userResetPwd({ email: obj.email, emailVerification }))
      }
      handleErr({
        err: errors,
        dispatch,
        alert: { msg: `Error Login! Please try again` },
        reduxType: COMPANY_LOGIN_FAIL,
      })
    }
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: { msg: `Error Login! Please try again` },
      reduxType: COMPANY_LOGIN_FAIL,
    })
    // dispatch({
    //   type: COMPANY_LOGIN_FAIL,
    //   payload: err.response?.data?.errors || [err?.message],
    // })
  }
}

// Verify OTP
export const verifyOtp = (obj, successHandler) => async (dispatch) => {
  dispatch({
    type: OTP_VERIFY_REQUEST,
  })
  try {
    const { data } = await axiosPublic.post('/users/verifyOtp', { email: obj.email, otp: obj.otp })

    const { success, errors } = data

    // if (!data?.success) throw new Error('Something went wrong')

    // if (loginSuccessHandler) loginSuccessHandler()
    if (success) {
      if (successHandler) successHandler()

      const companyData = data.user.company || data.user
      const userInfo = JSON.parse(JSON.stringify(data.user)) // deep clone
      delete userInfo.company // remove image (token too big)
      userInfo.company = { _id: companyData._id }
      handleIDB.put({ model: 'Auth', data: companyData, _id: 'companyData' })
      handleIDB.put({ model: 'Auth', data: userInfo, _id: 'userData' })
      handleIDB.put({ model: 'Auth', data: data.token, _id: 'XPERDRIVER_TOKEN' })

      if (obj.saveDevice) {
        handleIDB.put({
          model: 'Device',
          data: { email: obj.email, deviceSaved: true },
          _id: obj.email,
        })

        dispatch({
          type: SAVE_DEVICE_DETAILS,
          payload: { email: obj.email, deviceSaved: true },
        })
      } else {
        await handleIDB.delete({ model: 'Device', _id: obj.email })
        dispatch({
          type: SAVE_DEVICE_DETAILS,
          payload: null,
        })
      }
      dispatch({
        type: COMPANY_LOGIN_SUCCESS,
        payload: data,
      })
    } else {
      handleErr({
        err: errors,
        dispatch,
        alert: { msg: `Error verifing code` },
        reduxType: OTP_VERIFY_FAIL,
        payload: data,
      })
      // dispatch({
      //   type: OTP_VERIFY_FAIL,
      //   payload: data,
      // })
    }
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: { msg: `Error verifing code` },
      reduxType: OTP_VERIFY_FAIL,
    })
    // dispatch({
    //   type: OTP_VERIFY_FAIL,
    //   payload: errors?.length ? errors[0] : message || 'Error verifing code',
    // })
    // let otpErrorToast = (
    //   <CToast>
    //     <CToastBody style={{ color: 'red', fontSize: '1.1rem' }}>
    //       {err?.response?.data?.message || err.message || 'Something went wrong'}
    //     </CToastBody>
    //   </CToast>
    // )
    // addErrorToast(otpErrorToast)
  }
}

// Resend OTP
export const resendOtp = (email, successHandler) => async (dispatch) => {
  toast.warn(`Sending code! Please wait...`, {
    autoClose: false,
  })
  dispatch({
    type: OTP_RESEND_REQUEST,
  })
  try {
    const { data } = await axiosPublic.post('/users/resendOtp', { email })
    toast.dismiss()
    const { success, errors } = data

    // if (!data?.success) throw new Error('Something went wrong')

    // if (loginSuccessHandler) loginSuccessHandler()
    if (success) {
      setTimeout(() => {
        toast.success(`Code sent to your email!`)
      }, 77)

      if (successHandler) successHandler()
      dispatch({
        type: OTP_RESEND_SUCCESS,
        payload: data,
      })
    } else {
      handleErr({
        err: errors,
        dispatch,
        alert: { msg: `Error sending code! Please Try again` },
        reduxType: OTP_RESEND_FAIL,
        payload: data,
      })
      // dispatch({
      //   type: OTP_RESEND_FAIL,
      //   payload: data.message,
      // })
    }
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: { msg: `Error sending code! Please Try again` },
      reduxType: OTP_RESEND_FAIL,
    })

    // dispatch({
    //   type: OTP_RESEND_FAIL,
    //   payload: err.response.data.message,
    // })
    // let otpErrorToast = (
    //   <CToast>
    //     <CToastBody style={{ color: 'red', fontSize: '1.1rem' }}>
    //       {err?.response?.data?.message || err.message || 'Something went wrong'}
    //     </CToastBody>
    //   </CToast>
    // )
    // addErrorToast(otpErrorToast)
  }
}

//COMPANY LOGOUT
export const companyLogout = (obj) => async (dispatch) => {
  try {
    await dispatch({ type: COMPANY_LOGOUT_SUCCESS })
    handleIDB.localDB.models.forEach(
      async (model) =>
        model !== 'Device' && model !== 'Track' && (await handleIDB.deleteAll({ model })),
    )
    await store() // clean company data
    socket?.disconnect()
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: { msg: `Error! Please Try again` },
    })
  }
}
